exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-appointments-js": () => import("./../../../src/templates/appointments.js" /* webpackChunkName: "component---src-templates-appointments-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-dentist-js": () => import("./../../../src/templates/dentist.js" /* webpackChunkName: "component---src-templates-dentist-js" */),
  "component---src-templates-full-width-content-page-js": () => import("./../../../src/templates/fullWidthContentPage.js" /* webpackChunkName: "component---src-templates-full-width-content-page-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-patient-info-js": () => import("./../../../src/templates/patientInfo.js" /* webpackChunkName: "component---src-templates-patient-info-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

